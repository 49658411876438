// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-components-redirect-js": () => import("/Users/wangwanli/Documents/workspace/massnet.org/packages/mobile/src/components/redirect.js" /* webpackChunkName: "component---src-components-redirect-js" */),
  "component---src-pages-404-js": () => import("/Users/wangwanli/Documents/workspace/massnet.org/packages/mobile/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/Users/wangwanli/Documents/workspace/massnet.org/packages/mobile/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("/Users/wangwanli/Documents/workspace/massnet.org/packages/mobile/src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-develop-js": () => import("/Users/wangwanli/Documents/workspace/massnet.org/packages/mobile/src/pages/develop.js" /* webpackChunkName: "component---src-pages-develop-js" */),
  "component---src-pages-download-js": () => import("/Users/wangwanli/Documents/workspace/massnet.org/packages/mobile/src/pages/download.js" /* webpackChunkName: "component---src-pages-download-js" */),
  "component---src-pages-index-js": () => import("/Users/wangwanli/Documents/workspace/massnet.org/packages/mobile/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-research-js": () => import("/Users/wangwanli/Documents/workspace/massnet.org/packages/mobile/src/pages/research.js" /* webpackChunkName: "component---src-pages-research-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/wangwanli/Documents/workspace/massnet.org/packages/mobile/.cache/data.json")


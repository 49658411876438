/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
const VConsole = require("vconsole")

exports.onClientEntry = () => {
  const width =
    window.innerWidth ||
    window.document.documentElement.clientWidth ||
    window.document.body.clientWidth
  if (
    !/Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile/.test(
      navigator.userAgent
    ) &&
    width > 1080
  ) {
    window.location = `${process.env.MASS_DESKTOP}${window.location.pathname}`
  }
  if (process.env.NODE_ENV !== "production") {
    const vConsole = new VConsole()
    vConsole.show()
  }
}
